// \.(opacity|border-radius|box-shadow|transform)\((.+?)\);?$         =>         $1: $2;
// http://tumble.sneak.co.nz/post/928998513/fixing-the-background-bleed

@tariff-width-lg: 1263px;
@tariff-width-md: 1162px;
@tariff-width-sm: 998px;
@tariff-width-xs: 958px;

@tariff-first-lg: 178px + 50;
@tariff-first-sm: 154px + 30;

* {
  margin: 0;
  padding: 0;
}

html {
  color: #282828;
}

body {
  font-family: 'Open Sans', sans-serif;
}

img {
  border: 0;
  line-height: 0;
}

ul, ol {
  list-style: none;
}

.clear {
  clear: both;
}

.overflow {
  overflow: hidden;
}

a {
  color: #0164b8;
  transition: 0.2s;
  text-decoration: none;
}

a:hover {
  color: #ed3998;
}

h1, h2, h3, h4, h5, h6 {
}

/*
.html_format{
	line-height: 2.2em;
	h1, h2, h3, h4, h5, h6{}
	h1, h2, h3{font-size: 24px; margin-bottom: 22px;}
	h4, h5, h6{font-size: 20px; margin-bottom: 18px; font-weight: normal;}
	p{margin-bottom: 13px;}
	ul{list-style: none; margin-bottom: 18px;}
	ul li{padding-left: 15px;}
	ul li{margin-bottom: 8px; background: url(../images/tpl/li.png) left 12px no-repeat;}
	ol{list-style: decimal; padding-left: 30px;}
	ol li{}
	ol li span{color: #333333;}

	table{margin: 25px 0; border-collapse: collapse; border: 0; width: 100%;}
	th, td{padding: 5px 10px;border-color: white;}

	.big_image img{width: 110%; margin: 30px 0; margin-left: -5%;}
}
*/

//.wr{margin: 0 auto; max-width: 1500px; min-width: 960px; padding: 0 10px;}
.wr {
  margin: 0 auto;
  max-width: 1500px;
  min-width: 930px;
  padding: 0 30px;
}

.slide1 {
  background: url(../images/bg.jpg) center bottom no-repeat;
}

.slide1__inner {
  height: 1150px;
  position: relative;
}

.slide1__left {
  position: absolute;
  z-index: 1000;
  top: 330px;
  left: 0;
}

.slide1__left_margin {
  margin-left: 115px;
  margin-top: 35px;
}

.slide1__right {
  width: 360px;
  position: absolute;
  top: 315px;
  left: 1010px;
  z-index: 1000;
}

.slide1__right_title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
  line-height: 34px;
}

.slide1__angles {
  width: 759px;
  height: 1131px;
  background: url(../images/slide1_angles.png) no-repeat;
  position: absolute;
  z-index: 10;
  top: 110px;
  left: 380px;
}

.get_ecp {
  width: 838px;
  height: 181px;
  background: url(../images/get_ecp.png) no-repeat;
}

.slide2 {
}

.slide2__inner {
  height: 1050px;
  position: relative;
}

.slide2__top {
  width: 690px;
  margin: 0px auto;
  padding: 130px 0 50px;
  text-align: center;
  font-size: 13px;
  line-height: 24px;
}

.slide2__top--white {
  color: white;
}

.slide2__left_title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
  line-height: 34px;
}

.slide2__left {
  position: absolute;
  z-index: 1000;
  //top: 270px;
  left: 0;
  width: 365px;
}

.slide2__right {
  position: absolute;
  z-index: 1000;
  left: 1010px;
  //top: 375px;
  width: 370px;
}

.slide3 {
  background: url(../images/bg_invert.png) top repeat-x;
  overflow: hidden;
}

.slide3__inner {
  height: 888px;
  position: relative;
}

.slide3__left {
  position: relative;
  z-index: 700;
}

.slide3__left_title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.slide3__left_text {
  font-size: 13px;
  line-height: 24px;
  width: 577px;
}

.slide3__right {
  position: absolute;
  z-index: 700;
  top: 335px;
  left: 1000px;
}

.slide4 {
  background: url(../images/bg_green.jpg) center no-repeat;
  overflow: hidden;
}

.slide4__inner {
  height: 1049px;
  position: relative;
}

.slide5 {
  position: relative;
}

.slide5__inner {
  height: 720px;
  position: relative;
  padding-bottom: 70px;
}

.slide7 {
  position: relative;
  height: 920px;
}

.slide7__inner {
  height: 920px;
  position: relative;
}

.slide7__bg {
  background: url(../images/contacts_bg.jpg) center no-repeat;
  position: relative;
}

.map_btn {
  background: url(../images/map_btn.png) no-repeat;
  cursor: pointer;
  width: 125px;
  height: 34px;
  position: absolute;
  top: 663px;
  left: 50%;
  margin-left: -62px;
  z-index: 500;
}

.map_btn.act {
  background: url(../images/map_btn_act.png) no-repeat;
}

.work {
  font-size: 13px;
  text-align: center;
}

.footer {
  position: absolute;
  z-index: 100;
  bottom: 55px;
  font-size: 13px;
  text-align: center;
  width: 100%;
}

.footer__item {
  display: inline-block;
  margin: 0 10px;
}

.footer__item--dlm {
  width: 1px;
  height: 25px;
  background: #d4d4d4;
  vertical-align: middle;
}

.contacts {
  margin: 50px 0;
  text-align: center;
  margin-left: 100px;
}

.contacts__item {
  display: inline-block;
  width: 350px;
  vertical-align: top;
  font-size: 20px;
  text-align: left;
}

.contacts .order_btn {
  display: inline-block;
}

.contacts__item_top {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 30px;
}

.contacts__item--center {
  text-align: center;
  font-size: 42px;
  margin-right: 100px;
}

.contacts__item--center .contacts__item_top {
  margin-bottom: 15px;
}

.binoc {
  color: #a5a5a5;
  text-align: center;
  font-size: 13px;
  line-height: 24px;
  width: 300px;
  margin: 0 auto;
  padding: 20px 0 77px 0;
}

.licence {
  text-align: center;
  max-width: 1390px;
  margin: 0 auto;
}

.licence__item {
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 20px;
  width: 249px;
}

.licence__item img {
  width: 100%;
}

.downloads {
  width: 850px;
  margin: 0 auto;
}

.downloads table {
  margin-top: 10px;
  border-collapse: collapse;
  width: 100%;
}

.downloads td {
  padding: 10px;
  vertical-align: top;
  font-size: 13px;
  min-width: 120px;
}

.downloads th {
  font-size: 16px;
  text-align: left;
  padding: 10px;
}

.downloads tbody tr:nth-child(2n) td {
  background: #f7f7f7;
}

.downloads .dlm {
  width: 100%;
  height: 2px;
  background: url(../images/dlm.png) no-repeat;
  padding: 0;
}

.price {
  background: url(../images/opacity3.png) repeat;
  border-radius: 6px;
  padding: 8px;
  width: 970px;
  margin: 0 auto;
  position: relative;
  z-index: 50;
  margin-bottom: 70px;
}

.price__inner {
  border-radius: 6px;
  background: white;
  overflow: hidden;
}

.price table {
  border-collapse: collapse;
}

.price td {
  padding: 20px 40px;
  vertical-align: top;
  border: 1px solid #f0f0f0;
}

.price th {
  padding: 20px 40px;
  background: #cc3662;
  color: white;
  font-size: 20px;
}

.price td:nth-child(2) {
  font-size: 13px;
}

.price td:nth-child(3),
.price td.summ {
  font-weight: bold;
  color: #0164b8;
  font-size: 16px;
}

.price td b {
  font-size: 16px;
  color: #282828;
}

.shadow {
  height: 154px;
  background: url(../images/shadow.png) repeat-x;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.more_btn {
  cursor: pointer;
  width: 205px;
  height: 56px;
  margin: 66px auto 0 auto;
  background: url(../images/more_btn.png) no-repeat;
}

.more_btn--hide {
  background: url(../images/more_btn_hide.png) no-repeat;
}

.tariff {
  background: url(../images/opcaity.png) repeat;
  border-radius: 6px;
  width: 1003px;
  height: 667px;
  padding: 8px;
  margin: 0 auto;
}

.tariff__inner {
  background: url(../images/opacity2.png) repeat;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 2px grey;
}

.tariff__col:first-child {
  border-left: 0;
  width: @tariff-first-lg;

  .tariff__info {
    color: #cc3662;
  }
}

.tariff__col {
  float: left;
  border-left: 1px solid #d0cec8;
  height: 100%;
  width: 274px;
  width: (@tariff-width-lg - @tariff-first-lg - 5) / 4;
  position: relative;
}

.tariff__top {
  padding: 25px 35px;
  width: 100%;
  box-sizing: border-box;
  height: 145px;
  border-bottom: 1px solid #d0cec8;
}

.tariff__col:first-child .tariff__top {
  padding: 25px;
}

.tariff__top_title {
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 7px;
}

.tariff__top_title b {
  font-size: 17px;
}

.tariff__top_price {
  font-size: 24px;
  font-weight: bold;
  color: #0164b8;
}

.tariff__bottom {
  padding: 25px 28px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 537px;
}

.tariff__col:first-child .tariff__bottom {
  padding: 25px;
}

.tariff__bottom_dec {
  margin-bottom: 25px;
  min-height: 60px;
  font-size: 13px;
  line-height: 22px;
  height: 60px;
}

//.tariff__col{z-index: 5000;}
//.tariff__col+.tariff__col{z-index: 4900;}
//.tariff__col+.tariff__col+.tariff__col{z-index: 4800;}
//.tariff__col+.tariff__col+.tariff__col+.tariff__col{z-index: 4700;}
//.tariff__col+.tariff__col+.tariff__col+.tariff__col+.tariff__col{z-index: 4600;}

.tariff__btn {
  position: absolute;
  z-index: 100;
  bottom: 40px;
  left: 50%;
  margin-left: -87px;
}

.tariff__img {
  font-size: 17px;
}

.tariff__img img {
  margin-top: 2px;
  float: left;
  margin-right: 10px;
}

.tariff__info {
  font-size: 13px;
  line-height: 22px;
}

.tariff__hover_l, .tariff__hover_r {
  position: absolute;
  top: -5px;
  width: 263px;
  height: 677px;
  display: none;
  z-index: 1;
}

.tariff__hover_l {
  left: -5px;
  background: url(../images/hover_left.png) left no-repeat;
}

.tariff__hover_r {
  right: -5px;
  background: url(../images/hover_right.png) right no-repeat;
}

.tariff__col:hover {
  .tariff__hover_l, .tariff__hover_r {
    display: block;
  }
}

.tlist {
  font-size: 14px;
}

.tlist__item {
  padding-left: 30px;
  background: url(../images/tick_mini.png) 0 7px no-repeat;
  margin-bottom: 15px;
}

.notebook {
  width: 1790px;
  height: 573px;
  background: url(../images/notebook.png) no-repeat;
  position: absolute;
  z-index: 600;
  bottom: 0;
  left: 50%;
  margin-left: -1075px;
}

.use {
  margin-top: 10px;
  font-size: 12px;
  width: 635px;
}

.use__item {
  display: inline-block;
  margin-right: 30px;
  width: 80px;
  vertical-align: top;
  margin-bottom: 20px;
  background: white;
}

.use__item img {
}

.hand {
  position: absolute;
  z-index: 10;
  width: 731px;
  height: 798px;
  background: url(../images/hand.png) no-repeat;
  top: 252px;
  left: 350px;
}

.tick {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 2px solid #0062bb;
  position: relative;
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;

  + div {
    overflow: hidden;
  }
}

//.tick img{position: absolute; top: 50%; left: 50%; margin-left: -20px; margin-top: -25px;}

.tick img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -13px;
  margin-top: -10px;
  width: 27px;
  transition: all 0.4s;
}

.tick--see img {
  margin-left: -20px;
  margin-top: -25px;
  width: 54px;
}

.rec__item {
  clear: both;
  margin-bottom: 40px;
  font-size: 13px;
}

.rec__item b {
  font-size: 14px;
}

.list {
  font-size: 18px;
}

.list__item {
  margin-bottom: 22px;
  padding-left: 37px;
  background: url(../images/shtrih.png) 0 11px no-repeat;
}

.order_btn {
  background: url(../images/order_btn.png) no-repeat;
  cursor: pointer;
  width: 225px;
  height: 56px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 51px;
  transition: all 0.3s;
}

.order_btn:hover {
  opacity: 0.8;
}

.order_btn_sm {
  background: url(../images/order_btn_sm.png) no-repeat;
  cursor: pointer;
  width: 175px;
  height: 44px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  transition: all 0.3s;
}

.order_btn_sm:hover {
  opacity: 0.8;
}

.dignity {
  font-size: 14px;
  line-height: 24px;
}

.dignity__item {
  margin-bottom: 15px;
  padding-left: 60px;
  min-height: 50px;
}

.dignity__item.dig1 {
  background: url(../images/dignity/dig1.png) no-repeat;
}

.dignity__item.dig2 {
  background: url(../images/dignity/dig2.png) no-repeat;
}

.dignity__item.dig3 {
  background: url(../images/dignity/dig3.png) no-repeat;
}

.dignity__item.dig4 {
  background: url(../images/dignity/dig4.png) no-repeat;
}

.dignity__item.dig5 {
  background: url(../images/dignity/dig5.png) no-repeat;
}

.dignity__item.dig6 {
  background: url(../images/dignity/dig6.png) no-repeat;
}

.dignity__item.dig7 {
  background: url(../images/dignity/dig7.png) no-repeat;
}

.dignity__item.dig8 {
  background: url(../images/dignity/dig8.png) no-repeat;
}

.dignity__item.dig9 {
  background: url(../images/dignity/dig9.png) no-repeat;
}

.dignity__item.dig10 {
  background: url(../images/dignity/dig10.png) no-repeat;
}

.header {
  padding: 40px 0 35px 0;
  font-size: 16px;
  position: relative;
  z-index: 5000;
}

.header__logo {
  margin: 0 auto;
  width: 400px;
  text-align: center;
}

.header__left, .header__right {
  margin-top: 5px;
}

.header__left {
  float: left;
}

.header__right {
  float: right;
}

.header__phone {
  padding-left: 37px;
  background: url(../images/phone.png) 0 5px no-repeat;
  font-size: 26px;
  line-height: 39px;
}

.header__order {
  text-decoration: underline;
  padding-left: 37px;
  background: url(../images/galka.png) 0 2px no-repeat;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  cursor: pointer;
  color: #2479c2;
  &:hover {
    color: #ed3998;
  }
}

.hmenu {
  text-align: center;
  font-weight: bold;
  color: #0164b8;
  position: relative;
  z-index: 5000;
}

.hmenu__item {
  display: inline-block;
  margin: 0 7px;
  cursor: pointer;
  transition: 0.2s;
}

.hmenu__item:hover {
  color: #ed3998;
}

.hmenu__item--wr {
  width: 1px;
  background: #cacaca;
  height: 18px;
  vertical-align: middle;
  cursor: default;
}

//.ecp{width: 230px; height: 739px; background: url(../images/ecp.png) no-repeat; position: absolute; z-index: 500; top: 260px; left: 630px;}
.ecp {
  width: 822px;
  height: 1073px;
  background: url(../images/ecp.png) no-repeat;
  position: absolute;
  z-index: 500;
  top: 115px;
  left: 334px;
  transition: all 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.ecp.step2 {
  transform: scale(0.35, 0.35);
  top: 1200px;
}

.ecp.step3 {
  top: 2207px;
  transform: scale(0.35, 0.35) rotate(180deg);
}

.ie8 .ecp2 {
  width: 100px;
  height: 272px;
  background: url(../images/ecp2.png) no-repeat;
  position: absolute;
  z-index: 500;
  top: 447px;
  left: 683px;
}

.ie8 .ecp3 {
  width: 71px;
  height: 210px;
  background: url(../images/ecp3.png) 0 0px no-repeat;
  position: absolute;
  z-index: 500;
  top: 416px;
  left: 709px;
}

.down_btn {
  width: 180px;
  height: 160px;
  background: url(../images/pattern.png) no-repeat; /*background: #0062BB;*/
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 1015px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.down_btn__text {
  text-align: center;
  margin-top: 50px;
}

.down_btn__arrow {
  text-align: center;
  margin-top: 20px;
}

.down_btn--left {
  left: 0;
}

.slide2 .wr {
  position: relative;
}

.slide2__ang_left {
  width: 286px;
  height: 548px;
  background: url(../images/angles/slide2_left.png) no-repeat;
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: -1050px;
}

.slide2__ang_right {
  width: 373px;
  height: 862px;
  background: url(../images/angles/slide2_right.png) no-repeat;
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: 577px;
}

.slide5 .wr {
  position: relative;
}

.slide5__ang_left {
  width: 401px;
  height: 529px;
  background: url(../images/angles/slide5_left.png) no-repeat;
  position: absolute;
  top: 290px;
  left: 50%;
  margin-left: -886px;
}

.slide5__ang_right {
  width: 404px;
  height: 845px;
  background: url(../images/angles/slide5_right.png) no-repeat;
  position: absolute;
  top: 126px;
  left: 50%;
  margin-left: 485px;
}

.slide6 .wr {
  position: relative;
}

.slide6__ang_left {
  width: 281px;
  height: 635px;
  background: url(../images/angles/slide6_left.png) no-repeat;
  position: absolute;
  top: 290px;
  left: 50%;
  margin-left: -952px;
}

.slide6__ang_right {
  width: 283px;
  height: 596px;
  background: url(../images/angles/slide6_right.png) no-repeat;
  position: absolute;
  top: 126px;
  left: 50%;
  margin-left: 668px;
}

.slide6 tbody tr:hover td {
  background: #deebf6;
}

#gmap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

body {
  position: relative;
}

.popup {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
}

.popup__shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url(../images/popup_shadow.png) repeat;
  z-index: 10000;
  top: 0;
  left: 0;
}

.popup__wr {
  width: 820px;
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: -410px;
  background: url(../images/popup_bg.png) repeat;
  z-index: 15000;
  border-radius: 4px;
  padding: 8px;
}

.popup__inner {
  background: white;
  border-radius: 4px;
  padding: 40px 50px;
}

.popup__title {
  text-align: center;
  margin: 13px 0 25px 0;
  position: relative;
}

.popup__close {
  background: url(../images/close.png) no-repeat;
  width: 27px;
  height: 27px;
  top: -53px;
  left: 788px;
  position: absolute;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s;
}

.popup__close:hover {
  opacity: 1;
}

.popup__form_item {
  margin: 0 0 24px 0;
}

.popup__form_item input {
  width: 320px;
  height: 51px;
  box-shadow: 0 0 0 2px #95b6d2;
  border-radius: 1px;
  padding-left: 16px;
  font-size: 16px;
  border: none;
  outline: 0;
}

.popup__col textarea {
  width: 328px;
  height: 169px;
  box-shadow: 0 0 0 2px #95b6d2;
  border-radius: 1px;
  padding: 16px;
  border: none;
  outline: 0;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

.popup__check {
  float: left;
  width: 340px;
  font-size: 14px;
  padding: 0 20px 20px 0;
}

.popup__check input[type="checkbox"] {
  display: none;
}

.popup__checkbox {
  width: 36px;
  height: 29px;
  margin-right: 8px;
  float: left;
}

.popup__label {
  width: 270px;
  float: left;
  cursor: pointer;
  margin-top: 5px;
}

.nonchecked {
  background: url(../images/checkbox.png) no-repeat;
}

.checked {
  background: url(../images/checkbox.png) 0 -29px no-repeat;
}

.popup__check_option {
  font-weight: bold;
  color: #0164b8;
}

.popup__check_price {
  font-weight: bold;
  color: #d3375f;
}

.popup__order {
  width: 100%;
  text-align: center;
  margin-top: 28px;
}
.popup__tip {
  color: #999;
  margin: 0.5em 0;
  font-size: 90%;
}

.logo_fixed {
  opacity: 0;
  transition: all 0.3s;
  position: fixed;
  top: 7px;
  left: 30px;
  z-index: 3000;
}

.slide1.fix_menu {
  .hmenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    background: white;
    box-shadow: 0px 1px 2px #adabab;
    transition: all 0.3s;
    z-index: 2000;
  }
  .header__phone {
    display: none;
    &.fixed {
      position: fixed;
      display: block;
    }
    top: 2px;
    right: 30px;
    z-index: 3000;
    transition: all 0.3s;
    font-size: 18px;
    cursor: pointer;
    color: #2479c2;
    &:hover {
      color: #ed3998;
    }

  }
  .logo_fixed {
    opacity: 1;
  }
}

.w1500over {
  .tariff {
    width: @tariff-width-lg;
  }
  .tariff__col + .tariff__col {
    width: 270px;
    width: (@tariff-width-lg - @tariff-first-lg - 5) / 4;
  }
  .tariff__hover_l, .tariff__hover_r {
    width: 230px;
  }
}

.w1500 {
  .slide1__angles {
    left: 330px;
  }
  .slide1__right {
    left: 960px;
  }
  .slide2__right {
    left: 960px;
  }
  .ecp {
    left: 284px;
  }

  &.ie8 .ecp2 {
    left: 284+350px;
  }
  &.ie8 .ecp3 {
    left: 284+350px;
  }

  .hand {
    left: 300px;
  }

  .tariff {
    width: @tariff-width-lg;
  }
  .tariff__col + .tariff__col {
    width: 270px;
    width: (@tariff-width-lg - @tariff-first-lg - 5) / 4;
  }
  .tariff__hover_l, .tariff__hover_r {
    width: 230px;
  }
}

.w1400 {
  .slide1__angles {
    left: 280px;
  }
  .slide1__right {
    left: 910px;
  }
  .slide2__right {
    left: 910px;
  }
  .ecp {
    left: 234px;
  }

  &.ie8 .ecp2 {
    left: 234+350px;
  }
  &.ie8 .ecp3 {
    left: 234+350px;
  }

  .hand {
    left: 250px;
  }

  .tariff {
    width: @tariff-width-lg;
  }
  .tariff__col + .tariff__col {
    width: 270px;
    width: (@tariff-width-lg - @tariff-first-lg - 5) / 4;
  }

  .tariff__hover_l, .tariff__hover_r {
    width: 230px;
  }

}

.w1300 {
  .slide1__angles {
    left: 230px;
  }
  .slide1__right {
    left: 860px;
    width: 295px;
  }
  .slide2__right {
    left: 860px;
  }
  .ecp {
    left: 184px;
  }

  &.ie8 .ecp2 {
    left: 184+350px;
  }
  &.ie8 .ecp3 {
    left: 184+350px;
  }

  .hand {
    left: 200px;
  }

  .slide3__right {
    left: 920px
  }

  .down_btn {
    left: 965px;
  }
  .down_btn--left {
    left: 0;
  }

  .contacts__item {
    width: 300px;
  }
  .contacts__item--center {
    font-size: 32px;
  }

  .dignity {
    font-size: 13px;
    line-height: 19px;
  }

  .slide1__left_margin {
    margin-left: 35px;
  }
  .list {
    font-size: 14px;
  }

  .tariff__top {
    font-size: 14px;
  }
  .tariff__bottom {
    font-size: 14px;
  }

  .tariff {
    width: @tariff-width-md;
  }
  .tariff__top_title {
    font-size: 12px;
  }
  .tariff__top_title b {
    font-size: 14px;
  }
  .tariff__bottom_dec {
    font-size: 12px;
    line-height: 15px;
  }
  .tlist {
    font-size: 13px;
  }
  .tariff__col + .tariff__col {
    width: 245px;
    width: (@tariff-width-md - @tariff-first-lg - 5) / 4;
  }

  .get_ecp {
    width: 429px;
    height: 249px;
    background: url(../images/get_ecp_mini.png) no-repeat;
  }

  .slide1.fix_menu {
    .hmenu {
      font-size: 13px;
    }
  }

  .tariff__hover_l, .tariff__hover_r {
    width: 200px;
  }
}

.w1200 {
  .slide1__angles {
    left: 180px;
  }
  .slide1__right {
    left: 770px;
    width: 295px;
  }
  .slide2__right {
    left: 770px;
  }
  .ecp {
    left: 134px;
  }

  &.ie8 .ecp2 {
    left: 134+350px;
  }
  &.ie8 .ecp3 {
    left: 134+350px;
  }

  .hand {
    left: 150px;
  }

  .slide3__right {
    left: 820px
  }

  .down_btn {
    left: 875px;
  }
  .down_btn--left {
    left: 0;
  }

  .contacts__item {
    width: 300px;
  }
  .contacts__item--center {
    font-size: 32px;
  }

  //	.dignity{font-size: 11px;}

  .list {
    font-size: 14px;
  }

  .rec {
    font-size: 14px;
  }

  .tariff {
    width: @tariff-width-sm;
  }
  .tariff__col:first-child {
    width: @tariff-first-sm;
  }
  .tariff__col + .tariff__col {
    width: 210px;
    width: (@tariff-width-sm - @tariff-first-sm - 5) / 4;
  }
  .tariff__top {
    padding: 25px 20px;
  }
  .tariff__bottom {
    padding: 25px 15px;
  }

  .tlist__item {
    padding-left: 20px;
    background-position: 0 2px;
  }
  .tlist {
    font-size: 11px;
  }

  .slide1.fix_menu {
    .header__phone {
      opacity: 0;
      z-index: 0;
      position: relative;
    }
    .logo_fixed {
      opacity: 0;
      z-index: 0;
      position: relative;
    }
  }

  .tariff__hover_l, .tariff__hover_r {
    width: 180px;
  }
}

.w1100 {
  .slide1__angles {
    left: 130px;
  }
  .slide1__right {
    left: 715px;
    width: 275px;
  }
  .slide2__right {
    left: 715px;
  }
  .ecp {
    left: 84px;
  }

  &.ie8 .ecp2 {
    left: 84+350px;
  }
  &.ie8 .ecp3 {
    left: 84+350px;
  }

  .hand {
    left: 100px;
  }

  .slide3__right {
    left: 780px
  }

  .down_btn {
    left: 800px;
  }
  .down_btn--left {
    left: 0;
  }

  .contacts__item {
    width: 260px;
  }
  .contacts__item--center {
    font-size: 32px;
  }

  //	.dignity{font-size: 11px;}

  .slide1__left_margin {
    margin-left: 0px;
  }
  .list {
    font-size: 14px;
  }

  .rec {
    font-size: 14px;
  }

  .tariff__hover_l, .tariff__hover_r {
    width: 150px;
  }
}

.w1024 {
  .slide1__angles {
    left: 100px;
  }
  .slide1__right {
    left: 680px;
    width: 275px;
  }
  .slide2__right {
    left: 680px;
  }
  .ecp {
    left: 54px;
  }

  &.ie8 .ecp2 {
    left: 84+350px;
  }
  &.ie8 .ecp3 {
    left: 84+350px;
  }

  .hand {
    left: 80px;
  }

  .slide3__right {
    left: 725px
  }

  .down_btn {
    left: 770px;
  }
  .down_btn--left {
    left: 0;
  }

  .contacts__item {
    width: 230px;
    font-size: 16px;
  }
  .contacts__item--center {
    font-size: 28px;
  }

  //	.dignity{font-size: 11px;}

  .slide1__left_margin {
  }
  .list {
    font-size: 14px;
  }

  .rec {
    font-size: 14px;
  }

  .price {
    width: 955px;
    margin-left: -22px;
  }

  .tariff {
    width: @tariff-width-xs;
    margin-left: -20px;
  }
  .tariff__col + .tariff__col {
    width: (@tariff-width-xs - @tariff-first-sm - 5) / 4;
  }

  .tariff__hover_l, .tariff__hover_r {
    width: 130px;
  }
}

#test {
  font-size: 32px;
  font-weight: bold;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  display: none;
}

ul.tariff_features {
  div {
    display: none;
    position: absolute;
    left: 125px;
    top: -8px;
    width: 250px;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.24);
    z-index: 250;
    padding: 10px;
    font-size: 12px;
    color: #282828;
    border-radius: 4px;
    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.24);
      background: #fff;
      position: absolute;
      left: -5px;
      top: 12px;
      transform: rotate(45deg);
      transform-origin: center;
    }
  }

  li {
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;
    color: #0164b8;
    margin-bottom: 10px;

    &:hover {
      color: #ed3998;
      div {
        display: block;
      }
    }
  }
}

.end {
}

#top-teaser {
  width: 100%;
  display: block;
}

.my-list ol {
  list-style-type: decimal;
  font-size: 10px;
  margin-left: 10px;
  line-height: 1.3;
}
